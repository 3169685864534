<template>
  <v-card color="#F5F5F5" class="rounded-0">
    <v-card-text>
      <v-row>
        <v-col cols="12" class="">
          <v-row>
            <v-col cols="8" sm="5" md="6" class="text-start subtitle-1 font-weight-medium">
              <span>Alertas</span><br />
              <span class="caption font-weight-light">
                {{ orderBy }}
              </span>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="text-center font-weight-light">
              <v-select v-model="alertSortOptionData" :items="alertSortOptions" @change="$emit('showAlerts')" label="Filtrar" outlined dense>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="text-center font-weight-light">
              <v-text-field v-model="paginationParams.per_page" label="Quantidade de alertas" outlined dense
                @change="$emit('showAlerts')" type="number">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="2">
              <excel :data="[...alerts]" :width="'100%'" icon="mdi-download" button_name="Exportar" :file_name="`${patient.name.replaceAll(
                ' ',
                '_'
              )}_alertas.xls`" :json_fields="json_fields_alert" />
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="alerts.length" cols="12" class="pt-0">
          <v-card width="100%">
            <v-card-text class="d-flex no-wrap overflow-x-auto">
              <div class="pa-1" v-for="(item, index) in alerts" :key="index">
                <v-card class="align-self-end" outlined width="250" height="100%">
                  <v-toolbar elevation="0" :color="item.color" dark>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="subtitle-2">{{
                      item.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="subtitle-1 black--text font-weight-bold text-center pb-0">
                        {{ item.patient.name }}
                      </v-col>
                      <v-col cols="12" class="text-h5 font-weight-bold text-center pb-0"
                        :style="'color: ' + item.color">{{
                          item.title === "Nenhuma notificação"
                            ? "Normal"
                            : item.value
                        }}
                      </v-col>
                      <v-col cols="12" class="text-center pt-0">
                        {{
                          item.title === "Nenhuma notificação"
                            ? ""
                            : (item && item.title || '').split(" ")[0]
                        }}
                      </v-col>
                      <v-col cols="12" class="subtitle-2 text-center pt-0">
                        {{ formatLastUpdate(item.created_at) }}
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-divider></v-divider>
                      </v-col>
                      <v-col cols="12" class="subtitle-2 text-center pt-0">
                        <v-btn text @click="
                          $router.push({
                            name: 'AttendancePatientCreate',
                            params: {
                              patient_id: $route.params.id,
                              alert_id: item.id,
                            },
                          })
                        ">
                          <v-icon>mdi-plus-circle-outline</v-icon>
                          Novo Atendimento

                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="!alerts.length" cols="12" class="pt-0 text-center grey--text">Nenhum alerta encontrado</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    Excel: () => import("../../../components/Excel/Excel.vue"),
  },
  props:{
    alerts: [],
    patient: {},
    paginationParams: {},
    alertSortOption: {},
  }, 
  data() {
    return {
      orderBy: "Ordenados por data de criação",
      teste: [
        {
          text: 'mais novos', value: 'teste'
        }
      ],
      json_fields_alert: {
        Id: "id",
        Título: {
          field: "title",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        Valor: "value",
        Risco: "risk",
        Paciente: {
          field: "patient.name",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        Data: {
          field: "created_at",
          callback: (e) => {
            const day = e.slice(8, 10);
            const month = e.slice(5, 7);
            const year = e.slice(0, 4);
            const time = e.slice(11, 19);

            let textFormatted = "";

            for (
              var i = 0;
              i < `${day}/${month}/${year} às ${time}`.split(" ").length;
              i++
            ) {
              textFormatted += `${`${day}/${month}/${year} às ${time}`.split(" ")[i]
                }\n`;
            }

            return textFormatted;
          },
        },
      },
      alertSortOptions: [
        { text: 'Mais recentes', value: { order_by: 'created_at', order_sort: 'desc' } },
        { text: 'Mais antigos',  value: { order_by: 'created_at', order_sort: 'asc' } },
      ],
    }
  },
  methods:{
    formatLastUpdate(date) {
      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
  },
  computed: {
    alertSortOptionData: {
      get() {
        return this.alertSortOption
      },
      set(val) {
        this.$emit("update:alertSortOption", val)
      }
    }
  }
}
</script>