<template>
  <v-row>
    <v-col>
      <delete-user-dialog
        :open="openDeletePatient"
        @accepted="onDeletedPatientDialog"
        @close="openDeletePatient = false"
      />
      <Overlay :loading="loading" />
      <v-dialog v-model="createNotificationModal" max-width="300">
        <v-card width="auto">
          <v-card-title
            style="color: #1c3b7b"
            class="pl-13 pr-13 text-h6 font-weight-medium text-center"
          >
            Enviar notificação
          </v-card-title>
          <v-card-text>
            <v-form ref="formNotification" v-model="validateFormNotification">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <span
                    style="color: #1c3b7b"
                    class="subtitle-2 font-weight-regular"
                  >
                    Título
                  </span>
                  <v-text-field
                    v-model="createNotification.title"
                    placeholder="Título da notificação"
                    required
                    outlined
                    dense
                    :rules="titleNotificationRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <span
                    style="color: #1c3b7b"
                    class="subtitle-2 font-weight-regular"
                  >
                    Descrição
                  </span>
                  <v-text-field
                    v-model="createNotification.description"
                    placeholder="Título da notificação"
                    required
                    outlined
                    dense
                    :rules="descriptionNotificationRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="flex-column align-center">
            <v-btn
              width="95%"
              class="white--text pa-5"
              color="#1C3B7B"
              @click="onAcceptedCreateNotification"
              :disabled="!validateFormNotification"
            >
              Enviar
            </v-btn>
            <v-btn
              width="95%"
              class="caption font-weight-light pa-5 mt-1 ml-0"
              color="#323F4B"
              text
              @click="onCloseCreateNotification"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ButtonlessAlert
        :open="createdNotificationModal"
        width="330px"
        title="Notificação enviada com sucesso!"
        titleButton="Voltar para perfil"
        titleCloseButton="Voltar para dashboard"
        description="mdi-check-circle"
        iconSize="100"
        iconColor="#47A30E"
        @accepted="onCloseCreatedNotificationDialog('/')"
        @close="onCloseCreatedNotificationDialog('/dashboard')"
      />
    </v-col>
    <v-col cols="12">
      <v-row class="pa-5 justify-center align-center">
        <v-col cols="12" sm="4" class="text-h6 d-flex text-center">
          <v-row class="align-center">
            <v-col cols="12" sm="3" md="2">
              <v-avatar class="" color="primary" size="50">
                {{ `${patient.name ? patient.name.slice(0, 1) : "D"}` }}
              </v-avatar>
            </v-col>
            <v-col
              cols="12"
              sm="9"
              md="10"
              :class="{
                'text-center': $vuetify.breakpoint.mobile,
                'text-left': !$vuetify.breakpoint.mobile,
              }"
            >
              {{ patient.name || "Desconhecido" }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12" lg="8">
          <v-row class="justify-end">
            <v-col cols="12" lg="4" v-if="tab == 1">
              <v-btn
                color="primary"
                width="100%"
                @click="
                  $router.push({
                    path: `/patient/${$route.params.id}/anamnese`,
                    params: patient,
                  })
                "
              >
                Nova anamnese
              </v-btn>
            </v-col>
            <v-col cols="12" lg="3">
              <v-btn
                class="not caption"
                color="#1C3B7B"
                outlined
                width="100%"
                @click="createNotificationModal = true"
              >
                Enviar notificação
              </v-btn>
            </v-col>
            <v-col cols="12" lg="3">
              <v-btn
                class="not caption"
                color="#1C3B7B"
                outlined
                width="100%"
                @click="$router.push({ name: 'EditPatient', params: patient })"
              >
                Editar Dados
              </v-btn>
            </v-col>
            <v-col cols="12" lg="2">
              <v-btn
                @click="openDeletePatient = true"
                class="not caption"
                color="red"
                outlined
                width="100%"
              >
                Desativar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-tabs v-model="tab" fixed-tabs>
        <v-tab> Dados gerais </v-tab>
        <v-tab> Anamnese </v-tab>
        <v-tab> Sinais vitais </v-tab>
        <v-tab> Alertas </v-tab>
        <v-tab> Prontuários </v-tab>
        <!-- <v-tab> Localização </v-tab> -->
      </v-tabs>
    </v-col>
    
    <v-col cols="12">
      <v-tabs-items v-model="tab">
        <!-- DADOS GERAIS -->
        <v-tab-item class="pa-3">
          <GeneralData :patient="patient"/>
        </v-tab-item>
        
        <!-- ANAMNESE -->
        <v-tab-item class="pa-3">
          <v-card v-if="!seeAnswer" color="#F5F5F5" class="rounded-0">
            <v-card-title>
              <v-row class="align-center">
                <v-col cols="12" sm="8" md="10">
                  <v-text-field
                    v-model="searchAnamnese"
                    prepend-icon="mdi-magnify"
                    label="Procurar"
                    single-line
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                  <excel
                    :data="anamnesis"
                    :json_fields="json_fields_anamnesis"
                    button_name="Exportar"
                    icon="mdi-download"
                    :file_name="
                      patient.name.replaceAll(' ', '_') + '_anamnesis.xls'
                    "
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              :headers="patientHeaders"
              :items="anamnesis"
              :search="searchAnamnese"
              :loading="!anamnesis"
              item-key="key"
              :custom-filter="filterOnlyText"
            >
              <template v-slot:[`item.type`]="{ item }">
                <div @click="onShowAnamneseAnswer(item)" class="">
                  {{ item.type }}
                </div>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <div @click="onShowAnamneseAnswer(item)" class="">
                  {{ item.date }}
                </div>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn @click="onShowAnamneseAnswer(item)" class="primary"
                  >Ver respostas</v-btn
                >
              </template>
            </v-data-table>
          </v-card>
          <v-card v-if="seeAnswer" color="#F5F5F5" class="rounded-0">
            <v-card-text>
              <anamnese
                :anamnese="currentAnamnese"
                :patient="patient"
                :step.sync="seeAnswer"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- SINAIS VITAIS -->
        <v-tab-item class="pa-3">
          <VitalSigns :patient="patient" :dataLineExcel="dataLineExcel" :dataLine="dataLine" :showSensorCharts="showSensorCharts"/>
        </v-tab-item>

        <!-- ALERTAS -->
        <v-tab-item class="pa-3">
          <AlertsProfile :alerts="alerts" :patient="patient" @showAlerts="showAlerts()" :paginationParams="paginationParams" :alertSortOption.sync="alertSortOption"/>
        </v-tab-item>

        <!-- PRONTUÁRIOS -->
        <v-tab-item class="pa-3">
          <MedicalRecords :attendancies="attendancies" />
        </v-tab-item>

        <!-- LOCALIZAÇÃO -->
        <v-tab-item class="pa-3">
          <Localization/>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ButtonlessAlert from "../../components/Dialogs/simpleButtonlessAlert.vue";
import DeleteUserDialog from "../../components/Dialogs/DeleteUser.vue";
import Overlay from "../../components/Overlay/Overlay.vue";
import Anamnese from "./Anamnese/Index.vue";
import Localization from "./ProfileComponents/Localization.vue"
import MedicalRecords from "./ProfileComponents/MedicalRecords.vue";
import AlertsProfile from "./ProfileComponents/AlertsProfile.vue";
import VitalSigns from "./ProfileComponents/VitalSigns.vue";
import GeneralData from "./ProfileComponents/GeneralData.vue";
// import LineChartPatient from "../../components/Charts/LineChartPatient.vue";
// import FilterDialog from "../../components/Dialogs/Filter.vue";
// import Excel from "../../components/Excel/Excel.vue";

export default {
  components: {
    Localization,
    DeleteUserDialog,
    // LineChartPatient,
    ButtonlessAlert,
    // FilterDialog,
    Anamnese,
    Overlay,
    Excel: () => import("../../components/Excel/Excel.vue"),
    MedicalRecords,
    AlertsProfile,
    VitalSigns,
    GeneralData
},
  data: () => ({
    tab: null,
    anamnesis: [],
    seeAnswer: false,
    currentAnamnese: {},
    searchAnamnese: "",
    validateFormNotification: false,
    openDeletePatient: false,
    alertSelected: {},
    createNotification: {
      title: "",
      description: "",
    },
    attendanceForm: {
      subject_matter: "",
      sensor: "",
      service: "",
      alert_id: null,
      annotation: "",
    },
    attendanceWatch: {
      serviceNumber: "",
      subject_matter: "",
      sensor: "",
      service: "",
      annotation: "",
      created_at: "",
      alert: {
        risk: "",
        status: "",
        color: "".toString,
        colorC: "".toString,
      },
    },
    subjectRules: [(v) => !!v || "Assunto é obrigatório"],
    sensorRules: [(v) => !!v || "Sensor é obrigatório"],
    serviceRules: [(v) => !!v || "Serviço é obrigatório"],
    titleNotificationRules: [(v) => !!v || "Título é obrigatório"],
    descriptionNotificationRules: [(v) => !!v || "Descrição é obrigatório"],
    initialDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    finalDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    initialDateAlert: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    finalDateAlert: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    createNotificationModal: false,
    createdNotificationModal: false,
    initialModalDate: false,
    finalModalDate: false,
    initialModalDateAlert: false,
    finalModalDateAlert: false,
    filterDialog: false,
    filterAlert: false,
    dialogAttendance: false,
    createAttendanceDialog: false,
    attendanceCreatedDialog: false,
    attendanceWatchDialog: false,
    loading: false,
    patient: {
      name: "",
      mainData: {
        fullName: "",
        birthDate: "",
        sex: "",
        nationality: "",
        cpf: "",
        rg: "",
        motherName: "",
      },
      contact: {
        childOrGuardian: "",
        childOrGuardianPhone: "",
        email: "",
        phone: "",
      },
      address: {
        address: "",
        state: "",
        cep: "",
        uf: "",
        number: "",
      },
      comments: {
        comments: "",
      },
      device: {
        model: "Galaxy Watch4",
        status: "",
        arm: "Direito",
        modeNotDisturb: "Desativado",
        language: "Português (Brasil)",
        lastUpdate: "",
      },
      vitalSigns: [],
    },
    orderBy: "Ordenados por data de criação",
    dataLineChart: {
      tooltip: {
        trigger: "axis",
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["Dom", "Seg", "Ter", "Quarta", "Qui", "Sex", "Sáb"],
      },
      yAxis: {
        type: "value",
      },
      series: [],
    },
    dataLine: {},
    dataLineExcel: [],
    alerts: [],
    attendancies: [],
    // lastUpdate: "Última atualização às 00:00h",
    itemMenu: 0,
    sleepTotalChart: [],
    itemFilterSelect: [
      "Todos",
      "Temperatura",
      "Batimentos",
      "Sbp",
      "Dbp",
      "Saturação",
      "Sono total",
    ],
    itemFilterSelectAlert: [
      "Todos",
      "Temperatura",
      "Batimentos",
      "Sbp",
      "Dbp",
      "Saturação",
      "Sono total",
    ],
    itemFilterSelectRisk: [
      { title: "Todos", color: "#83ACB5", colorCard: "#648F98" },
      { title: "Baixo", color: "#ED7F30", colorCard: "#D6691A" },
      { title: "Alto", color: "#CF4444", colorCard: "#C23535" },
    ],
    sensorLength: 6,
    json_fields_attendance: {
      Id: "id",
      Data: {
        field: "date",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Responsável: {
        field: "responsible",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Descrição: {
        field: "subject_matter",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Paciente: {
        field: "patient.name",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
    },
    json_fields_anamnesis: {
      Id: "id",
      Nome: {
        field: "type",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Data: {
        field: "date",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Questões: "questions",
      Respostas: "answers",
    },
    localization: "",
    paginationParams: {
      page: 1,
      per_page: 10,
      total: 0
    },
    alertSortOption: { order_by: 'created_at', order_sort: 'asc' },
  }),
  async mounted() {
    if (this.$route.params.type === "anamnese") this.tab = 1;
    if (this.$route.params.type === "alert") this.tab = 3;
    if (this.$route.params.type === "attendance") this.tab = 4;
    if (this.$route.params.type === "localization") this.tab = 5;

    await this.validatePatient();
    await this.showPatient();
    
    this.updateEveryXminutes(0.25);
  },
  methods: {
    ...mapActions("patients", ["logout"]),
    async getLocalization() {
      const response = await this.$axios.get(
        `https://eprevsaude.com.br/sys/map?cpf=${this.$route.params.cpf}`
      );
      this.localization = response.data;
    },
    async validatePatient() {
      this.loading = true;
      if (this.patient) {
        await this.showPatient();
        await this.showAlerts();
        await this.showStatusDevice();
        await this.showCalls();
        await this.showSensorCharts({
          user_id: this.$route.params.id,
        });
        await this.showAnamneseForms();
      }
      this.loading = false;
    },
    async showPatient() {
      try {
        this.loading = true;

        const response = await this.$axios("users/" + this.$route.params.id);

        this.lastUpdate = `Última atualização ${new Date().getHours()}:${
          new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes()
        } h`;

        this.loading = false;

        if (response.data) {
          const PatientLastSave = response.data.receive_last_items;

          if (PatientLastSave.ecg == null)
            PatientLastSave.ecg = {
              sbp: 0,
              dbp: 0,
              bpm: 0,
              blood_oxygen: 0,
            };

          if (PatientLastSave.temperature == null)
            PatientLastSave.temperature = {
              real_temperature: 0,
            };

          if (PatientLastSave.sleep == null)
            PatientLastSave.sleep = {
              deep_sleep_total: 0,
              light_sleep_total: 0,
            };

          if (PatientLastSave.device_alert == null) {
            PatientLastSave.device_alert = {
              status: "offline",
              created_at: null,
            };
          }

          this.patient = {
            id: response.data.id,
            name: response.data.name,
            mainData: {
              fullName: response.data.name,
              birthDate: this.formatBirthDate(response.data.birth_date),
              sex: response.data.sex == "F" ? "Feminino" : "Masculino",
              nationality: response.data.nacionality || "Não informado",
              cpf: response.data.cpf,
              rg: response.data.rg,
              motherName: response.data.mother_name || "Não informado",
            },
            contact: {
              childOrGuardian: "Não informado",
              childOrGuardianPhone: "Não informado",
              email: response.data.email,
              phone: response.data.phone,
            },
            address: response.data.address || {},
            comments: {
              comments:
                response.data.comments || "Nenhum comentário existente.",
            },
            device: {
              model: "Galaxy Watch4",
              status:
                PatientLastSave.device_alert.status == "online"
                  ? "Ligado"
                  : "Desligado",
              arm: "Direito",
              modeNotDisturb: "Desativado",
              language: "Português (Brasil)",
              lastUpdate: this.dataTimeFormmating(
                PatientLastSave.device_alert.created_at
                  ? PatientLastSave.device_alert.created_at
                  : "00/00/0000 às 00:00h"
              ),
            },
            vitalSigns: [
              {
                id: 1,
                title: "Pressão arterial",
                value: `${PatientLastSave?.ecg?.dbp || 0}x${PatientLastSave?.ecg?.sbp || 0}`,
                footer: "mmHg",
              },
              {
                id: 2,
                title: "Frequência cardíaca",
                value: PatientLastSave?.ecg?.bpm || 0,
                footer: "BPM",
              },
              // {
              //   id: 3,
              //   title: "Frequência respiratória",
              //   value: "20",
              //   footer: "RPM",
              // },
              {
                id: 3,
                title: "Temperatura",
                value: `${PatientLastSave?.temperature?.real_temperature || 0}°C`,
                footer: "Graus",
              },
              {
                id: 4,
                title: "Saturação",
                value: (PatientLastSave?.ecg?.blood_oxygen || 0) + "%",
                footer: "O²",
              },
              // {
              //   id: 5,
              //   title: "Sono",
              //   value:
              //     (
              //       (PatientLastSave.sleep.deep_sleep_total +
              //         PatientLastSave.sleep.light_sleep_total) /
              //       60
              //     ).toFixed(1) + "Hrs",
              //   footer: "Leve",
              // },
            ],
          };

          this.patientExcel = {
            id: response.data.id,
            name: response.data.name,
            birthDate: this.formatBirthDate(response.data.birth_date),
            sex: response.data.sex == "F" ? "Feminino" : "Masculino",
            nationality: response.data.nacionality || "Não informado",
            cpf: response.data.cpf,
            rg: response.data.rg,
            motherName: response.data.mother_name || "Não informado",
            childOrGuardian: "Não informado",
            childOrGuardianPhone: "Não informado",
            email: response.data.email,
            phone: response.data.phone,
            address: response.data.address || {},
            comments: response.data.comments || "Nenhum comentário existente.",
          };
        }
      } catch (error) {
        const msg = error.response.error;

        console.log("Show patient error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar o paciente." + msg,
        });
      }
    },
    async showAnamneseForms() {
      try {
        const response = await this.$axios("form_applications", {
          params: {
            user_id: this.$route.params.id,
          },
        });
        if (response.data) {
          response.data.map((anamnese) => {
            // Adds the responses received by the form application in their appropriate form items
            anamnese.form.form_items.forEach((formItem) => {
              formItem.form_item_answers = anamnese.form_item_answers.filter(
                (itemAnswer) => itemAnswer.form_item_id == formItem.id
              );
            });

            this.anamnesis = [
              {
                id: anamnese.id,
                type: anamnese.form.name,
                date: this.formatLastUpdate(anamnese.updated_at),
                form_items: anamnese.form.form_items,
                form_item_answers: anamnese.form_item_answers,
                questions: anamnese.form.form_items.length || 0,
                answers: anamnese.form_item_answers.length || 0,
              },
              ...this.anamnesis,
            ];
          });
        }
      } catch (error) {
        const msg = error.response.error;
        console.log("Show anamnese error: ", error);
        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text:
            "Ocorreu um erro ao tentar carregar os formulários de anamnesis. " +
            msg,
        });
      }
    },
    async showStatusDevice() {
      try {
        var currentDate = new Date();
        var dateOfMinuteLast = new Date(
          currentDate.getTime() - 20 * 60000
        ).toLocaleTimeString();
        const response = await this.$axios(
          `/device_alerts/${this.$route.params.id}`
        );
        if (response.data) {
          if (
            (response.data.created_at.slice(11, 19) <= dateOfMinuteLast &&
              response.data.status != "offline") ||
            response.data.created_at.slice(8, 10) <
              new Date().toLocaleDateString().slice(0, 2)
          ) {
            await this.$axios.post("/device_alerts/", {
              device_alert: {
                user_id: this.$route.params.id,
                status: "offline",
              },
            });
          }

          if (response.data.status != "offline") {
            this.patient.device.status = "Ligado";
          } else {
            this.patient.device.status = "Desligado";
          }

          this.patient.device.lastUpdate = this.dataTimeFormmating(
            response.data.created_at
          );
        } else {
          await this.$axios.post("/device_alerts/", {
            device_alert: {
              user_id: this.$route.params.id,
              status: "offline",
            },
          });
        }
      } catch (error) {
        const msg = error.response.error;
        console.log("Status device error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text:
            "Ocorreu um erro ao tentar carregar o status do dispositivo." + msg,
        });
      }
    },
    async showAlerts() {
      this.loading = true;
      try {
        const response = await this.$axios("alerts/" + this.$route.params.id, { params: {
          ...this.alertSortOption,
          per_page: this.paginationParams.per_page,
        }});

        this.alerts = response.data.map(alert => ({
          id: alert.id,
          user_id: this.patient && this.patient.id,
          title: this.onChangeAlertTemplate(alert).title,
          color: this.onChangeAlertTemplate(alert).color,
          colorC: this.onChangeAlertTemplate(alert).colorC,
          value: this.onChangeAlertTemplate(alert).value,
          risk: this.onChangeAlertTemplate(alert).risk,
          patient: {
            name: this.patient && this.patient.name,
            age: this.patient && this.patient.age,
            phone: this.patient && this.patient.phone,
          },
          created_at: alert.created_at,
        }));

        this.loading = false;
      } catch (error) {
        this.loading = false;

        console.log("Alerts error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar os alertas, tente novamente daqui alguns minutos!",
        });
      }
    },
    async showSensorCharts(params) {
      this.loading = true;
      try {
        let count = 0;
        const response = await this.$axios("/sensor_charts", { params });
        this.dataLineExcel = [];

        if (response.data) {
          this.dataLine = Object.assign({}, response.data);
          const days = [
            "Domingo",
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado",
          ];

          for (let i in response.data) {
            count = 0;
            Object.values(response.data[i]).map((e) => {
              if (e == null) e = 0;

              this.dataLineExcel.push({
                day: days[count],
                sensor: i,
                value: e,
              });

              count++;
            });
          }
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        const msg = error.response.error;

        console.log("Show sensor charts error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text:
            "Ocorreu um erro ao tentar carregar os dados semanais dos sensores." +
            msg,
        });
      }
    },
    async showCalls() {
      try {
        const response = await this.$axios("/attendances/", {
          params: {
            patient_id: this.$route.params.id,
          },
        });

        if (response.data) {
          response.data.map((el) => {
            this.attendancies = [
              {
                date: this.formatDateTime(el.updated_at),
                id: el.id,
                status: this.scheduleStatusFormatting(el.status),
                responsible: el.responsible.name,
                urgency: el.alert.risk == "high" ? "Alta" : "Baixa",
                subject_matter: el.subject_matter,
                sensor: el.sensor,
                service: el.service,
                annotation: el.annotation,
                patient: el.patient,
                alert: el.alert,
                alert_id: el.alert_id,
                created_at: this.formatDateTime(el.created_at),
              },
              ...this.attendancies,
            ];
          });
        }
      } catch (error) {
        const msg = error.response.error;

        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar os atendimentos." + msg,
        });
      }
    },
    watchAttendance(item) {
      this.attendanceWatchDialog = true;
      this.attendanceWatch = {
        serviceNumber: item.serviceNumber,
        subject_matter: item.subject_matter,
        sensor: item.sensor,
        service: item.service,
        annotation: item.annotation,
        created_at: this.dataTimeFormmating(item.created_at),
        alert: {
          risk: item.alert.risk,
          status:
            item.alert.status +
            " no dia " +
            this.dataTimeFormmating(item.alert.created_at),
          color: item.alert.color,
        },
      };
    },
    filterOnlyNumber(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "number" &&
        value.toString().indexOf(search) !== -1
      );
    },
    filterOnlyText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toLowerCase().toString().indexOf(search.toLowerCase()) !== -1
      );
    },
    onCloseFilterDialogGraphic() {
      this.filterDialog = false;
    },
    onAcceptedFilterDialogGraphic() {
      this.filterDialog = false;
    },
    onCloseFilterDialogAlert() {
      this.filterAlert = false;
    },
    onAcceptedFilterDialogAlert() {
      this.filterAlert = false;
    },
    formatAlertMessage(message) {
      switch (message) {
        case "temperature_dropped":
          return "Temperatura reduzida";
        case "temperature_rose":
          return "Temperatura elevada";
        case "beats_dropped":
          return "Batimentos reduzido";
        case "beats_rose":
          return "Batimentos elevados";
        case "dbp_dropped":
          return "Dbp reduzido";
        case "dbp_rose":
          return "Dbp elevado";
        case "sbp_dropped":
          return "Sbp reduzido";
        case "sbp_rose":
          return "Sbp elevado";
        case "blood_oxygen_dropped":
          return "Saturação reduzida";
        case "blood_oxygen_rose":
          return "Saturação elevada";
        case "total_sleep_dropped":
          return "Horas de sono reduzida";
        case "total_sleep_rose":
          return "Horas de sono elevada";
        default:
          return;
      }
    },
    formatAlertBackground(risk) {
      return risk == "high" ? "#CF4444" : "#ED7F30";
    },
    formatDateTime(dataTime) {
      const day = dataTime.slice(8, 10);
      const month = dataTime.slice(5, 7);
      const year = dataTime.slice(0, 4);
      const time = dataTime.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    onChangeAlertTemplate(alert) {
      if (!alert.alertable) {
        switch (alert.status) {
          case "falldown_exam":
            return {
              title: "Alerta de queda",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Alerta de queda",
              risk: alert ? alert.risk : "none",
            };
          case "sos_exam":
            return {
              title: "SOS",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "SOS pressionado",
              risk: alert ? alert.risk : "none",
            };
          case "fence_out_exam":
            return {
              title: "Limite de cerca quebrada",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Fora de Cerca",
              risk: alert ? alert.risk : "none",
            };
          case "fence_in_exam":
            return {
              title: "Area limite ultrapassada",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Area Limite",
              risk: alert ? alert.risk : "none",
            };
          case "low_battery_exam":
            return {
              title: "Dispositivo descarregando",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Energia",
              risk: alert ? alert.risk : "none",
            };
          case "wifi_in_exam":
            return {
              title: "Dentro da cerca de Wifi",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "WiFi",
              risk: alert ? alert.risk : "none",
            };
          case "wifi_out_exam":
            return {
              title: "Fora da cerca de Wifi",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "WiFi",
              risk: alert ? alert.risk : "none",
            };
          case "device_offline_exam":
            return {
              title: "Dispositivo offline",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Offline",
              risk: alert ? alert.risk : "none",
            };
          case "sedentary_exam":
            return {
              title: "Tempo Ocioso",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Sedentarismo",
              risk: alert ? alert.risk : "none",
            };
        }
      }
      const types = {
        temperature_dropped: {
          title: "Temperatura reduzida",
          color: "#CF4444",
          colorC: "#D6691A",
          value: alert.alertable.real_temperature + "°C",
          risk: alert ? alert.risk : "none",
        },
        temperature_rose: {
          title: "Temperatura elevada",
          color: "#CF4444",
          colorC: "#C23535",
          value: alert.alertable.real_temperature + "°C",
          risk: alert ? alert.risk : "none",
        },
        beats_dropped: {
          title: "Batimentos reduzidos",
          color: "#CF4444",
          colorC: "#D6691A",
          value: alert.alertable.bpm,
          risk: alert ? alert.risk : "none",
        },
        beats_rose: {
          title: "Batimentos elevados",
          color: "#CF4444",
          colorC: "#C23535",
          value: alert.alertable.bpm,
          risk: alert ? alert.risk : "none",
        },
        dbp_dropped: {
          title: "DBP reduzido",
          color: "#CF4444",
          colorC: "#D6691A",
          value: alert.alertable.dbp,
          risk: alert ? alert.risk : "none",
        },
        dbp_rose: {
          title: "DBP elevado",
          color: "#CF4444",
          colorC: "#C23535",
          value: alert.alertable.dbp,
          risk: alert ? alert.risk : "none",
        },
        sbp_dropped: {
          title: "SBP reduzido",
          color: "#CF4444",
          colorC: "#D6691A",
          value: alert.alertable.sbp,
          risk: alert ? alert.risk : "none",
        },
        sbp_rose: {
          title: "SBP elevado",
          color: "#CF4444",
          colorC: "#C23535",
          value: alert.alertable.sbp,
          risk: alert ? alert.risk : "none",
        },
        blood_oxygen_dropped: {
          title: "Oxigenação reduzida",
          color: "#CF4444",
          colorC: "#C23535",
          value: alert.alertable.blood_oxygen,
          risk: alert ? alert.risk : "none",
        },
        blood_oxygen_rose: {
          title: "Oxigenação elevada",
          color: "#CF4444",
          colorC: "#D6691A",
          value: alert.alertable.blood_oxygen,
          risk: alert ? alert.risk : "none",
        },
        total_sleep_dropped: {
          title: "Horas de sono reduzida",
          color: "#CF4444",
          colorC: "#D6691A",
          value:
            (alert.alertable.deep_sleep_total +
              alert.alertable.light_sleep_total) /
            60,
          risk: alert.alert ? alert.risk : "none",
        },
        total_sleep_rose: {
          title: "Horas de sono elevada",
          color: "#CF4444",
          value:
            (alert.alertable.deep_sleep_total +
              alert.alertable.light_sleep_total) /
            60,
          risk: alert.alert ? alert.risk : "none",
        },
      };

      return (
        types[alert ? alert.status : "nothing"] || {
          title: "Nenhuma notificação",
          color: "#256DF4",
          risk: "none",
        }
      );
    },
    onClickAlert(id) {
      var index = this.alerts.filter((alert) => alert.id == id)[0];
      this.alertSelected = this.alerts[this.alerts.indexOf(index)];
      this.attendanceForm.alert_id = this.alertSelected.id;
      this.dialogAttendance = true;
    },
    onAcceptedAttendanceDialog() {
      this.createAttendanceDialog = true;
      this.dialogAttendance = false;
    },
    onCloseAttendanceDialog() {
      this.dialogAttendance = false;
    },
    async onAcceptedCreateAttendanceDialog() {
      try {
        this.$axios.post("/attendances", {
          attendance: {
            alert_id: this.attendanceForm.alert_id,
            subject_matter: this.attendanceForm.subject_matter,
            sensor: this.attendanceForm.sensor,
            service: this.attendanceForm.sensor,
            annotation: this.attendanceForm.annotation,
          },
        });
        this.createAttendanceDialog = false;
        this.attendanceCreatedDialog = true;
      } catch (error) {
        this.createAttendanceDialog = false;

        console.log("Create attendance error: ", error);
      }
      this.$refs.form.reset();
    },
    onCloseCreateAttendanceDialog() {
      this.$refs.form.reset();
      this.createAttendanceDialog = false;
    },
    onCloseCreatedAttendanceDialog(path) {
      this.attendancies = [];
      this.showCalls();
      this.attendanceCreatedDialog = false;
      if (path == "/") return;
      this.$router.push({
        path: path,
      });
    },
    onCloseCreateNotification() {
      this.createNotificationModal = false;
      this.$refs.formNotification.reset();
    },
    async onAcceptedCreateNotification() {
      this.createNotificationModal = false;
      try {
        await this.$axios.post(`notifications`, {
          notification: {
            patient_id: this.$route.params.id,
            sender_id: this.currentUser.id,
            sender_type: "User",
            title: this.createNotification.title,
            description: this.createNotification.description,
          },
        }),
          (this.createdNotificationModal = true);
        this.$refs.formNotification.reset();
      } catch (error) {
        console.log("Notification: ", error);
      }
    },
    async onCloseCreatedNotificationDialog(path) {
      this.createdNotificationModal = false;
      if (path == "/") return;
      this.$router.push({
        path: path,
      });
    },
    async onDeletedPatientDialog(reason) {
      this.openDeletePatient = false;
      this.loading = true;

      try {
        await this.$axios("remove_relationship", {
          params: {
            patient_id: this.$route.params.id,
            doctor_id: this.currentUser.id,
            reason: reason,
          },
        });

        await this.$store.dispatch("patients/deletePatient", {
          patient: this.patient,
        });

        await this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Paciente removido com sucesso.",
        });

        this.$router.push({
          path: "/dashboard",
        });

        this.loading = false;
      } catch (error) {
        console.log("Delete patient error: ", error);
        this.$swal({
          toast: false,
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar remover o paciente",
        });

        this.loading = false;
      }
    },
    updateEveryXminutes(minutes) {
      setInterval(() => {
        if (!this.$route.params.id) {
          return;
        }
        this.showCalls();
        this.showPatient();
        this.showStatusDevice();
        this.showAlerts();
        this.showSensorCharts({
          user_id: this.$route.params.id,
        });
        this.showCalls();
      }, minutes * 60000);
    },
    formatBirthDate(date) {
      if (!date) return "Não informado";

      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);

      return `${day}/${month}/${year}`;
    },
    formatLastUpdate(date) {
      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    scheduleStatusFormatting(status) {
      switch (status) {
        case "scheduled":
          return {
            title: "Agendado",
            color: "#096FA9",
            value: status,
          };
        case "under_review":
          return {
            title: "Em análise",
            color: "#ED7F30",
            value: status,
          };
        case "concluded":
          return {
            title: "Concluído",
            color: "#09A90F",
            value: status,
          };
        case "closed":
          return {
            title: "Encerrado",
            color: "#CF4444",
            value: status,
          };
        case "forward":
          return {
            title: "Encaminhar equipe",
            color: "#CF4444",
            value: status,
          };
        case "get_family":
          return {
            title: "Entrar em contato familiar",
            color: "#CF4444",
            value: status,
          };
        default:
          return {
            title: "Agendado",
            color: "#096FA9",
            value: status,
          };
      }
    },
    dataTimeFormmating(dataTime) {
      var day = dataTime.slice(8, 10);
      var month = dataTime.slice(5, 7);
      var year = dataTime.slice(0, 4);
      var time = dataTime.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    dataTimeNotSeconds(dataTime) {
      var day = dataTime.slice(8, 10);
      var month = dataTime.slice(5, 7);
      var year = dataTime.slice(0, 4);
      var time = dataTime.slice(11, 16);

      return `${day}/${month}/${year} às ${time}`;
    },
    monthFormatting(month) {
      switch (Number(month)) {
        case 1:
          return "JAN";
        case 2:
          return "FEV";
        case 3:
          return "MAR";
        case 4:
          return "ABR";
        case 5:
          return "MAI";
        case 6:
          return "JUN";
        case 7:
          return "JUL";
        case 8:
          return "AGO";
        case 9:
          return "SET";
        case 10:
          return "OUT";
        case 11:
          return "NOV";
        case 12:
          return "DEZ";
        default:
          return "aqui";
      }
    },
    onChangePath(path) {
      this.$router.push({
        path: path,
      });
    },
    onShowAnamneseAnswer(anamnese) {
      this.currentAnamnese = anamnese;
      this.seeAnswer = true;
    },
  },
  
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    attendanceHeaders() {
      return [
        {
          text: "Data e Hora do atendimento",
          value: "date",
          width: "33%",
          align: "center",
        },
        {
          text: "N° do atendimento",
          value: "id",
          width: "33%",
          align: "center",
        },
        { text: "Ação", value: "menu", width: "33%", align: "center" },
      ];
    },
    patientHeaders() {
      return [
        {
          text: "Tipo de anamnese",
          value: "type",
          width: 300,
          align: "center",
        },
        { text: "Data", value: "date", width: 250, align: "center" },
        { text: "Ação", value: "action", width: 250, align: "center" },
      ];
    },
    
  },
};
</script>

<style scopped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.selected {
  background-color: #f5f5f5;
}
.not {
  font-size: 1vw;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.background_light {
  background: white;
}
.cardMobile {
  width: 120px;
  font-size: 0.8rem;
}
</style>
