var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-0",attrs:{"color":"#F5F5F5"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-start subtitle-1 font-weight-medium",attrs:{"cols":"8","sm":"5","md":"6"}},[_c('span',[_vm._v("Alertas")]),_c('br'),_c('span',{staticClass:"caption font-weight-light"},[_vm._v(" "+_vm._s(_vm.orderBy)+" ")])]),_c('v-col',{staticClass:"text-center font-weight-light",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-select',{attrs:{"items":_vm.alertSortOptions,"label":"Filtrar","outlined":"","dense":""},on:{"change":function($event){return _vm.$emit('showAlerts')}},model:{value:(_vm.alertSortOptionData),callback:function ($$v) {_vm.alertSortOptionData=$$v},expression:"alertSortOptionData"}})],1),_c('v-col',{staticClass:"text-center font-weight-light",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"label":"Quantidade de alertas","outlined":"","dense":"","type":"number"},on:{"change":function($event){return _vm.$emit('showAlerts')}},model:{value:(_vm.paginationParams.per_page),callback:function ($$v) {_vm.$set(_vm.paginationParams, "per_page", $$v)},expression:"paginationParams.per_page"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('excel',{attrs:{"data":[].concat( _vm.alerts ),"width":'100%',"icon":"mdi-download","button_name":"Exportar","file_name":((_vm.patient.name.replaceAll(
              ' ',
              '_'
            )) + "_alertas.xls"),"json_fields":_vm.json_fields_alert}})],1)],1)],1),(_vm.alerts.length)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-text',{staticClass:"d-flex no-wrap overflow-x-auto"},_vm._l((_vm.alerts),function(item,index){return _c('div',{key:index,staticClass:"pa-1"},[_c('v-card',{staticClass:"align-self-end",attrs:{"outlined":"","width":"250","height":"100%"}},[_c('v-toolbar',{attrs:{"elevation":"0","color":item.color,"dark":""}},[_c('v-spacer'),_c('v-toolbar-title',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.title))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"subtitle-1 black--text font-weight-bold text-center pb-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(item.patient.name)+" ")]),_c('v-col',{staticClass:"text-h5 font-weight-bold text-center pb-0",style:('color: ' + item.color),attrs:{"cols":"12"}},[_vm._v(_vm._s(item.title === "Nenhuma notificação" ? "Normal" : item.value)+" ")]),_c('v-col',{staticClass:"text-center pt-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(item.title === "Nenhuma notificação" ? "" : (item && item.title || '').split(" ")[0])+" ")]),_c('v-col',{staticClass:"subtitle-2 text-center pt-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.formatLastUpdate(item.created_at))+" ")]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticClass:"subtitle-2 text-center pt-0",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({
                          name: 'AttendancePatientCreate',
                          params: {
                            patient_id: _vm.$route.params.id,
                            alert_id: item.id,
                          },
                        })}}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")]),_vm._v(" Novo Atendimento ")],1)],1)],1)],1)],1)],1)}),0)],1)],1):_vm._e(),(!_vm.alerts.length)?_c('v-col',{staticClass:"pt-0 text-center grey--text",attrs:{"cols":"12"}},[_vm._v("Nenhum alerta encontrado")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }