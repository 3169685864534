<template>
<v-card color="#F5F5F5" class="rounded-0">
  <v-card-text>
    <v-row>
      <v-col cols="6" sm="9" md="10" class="subtitle-1 font-weight-medium mb-5">
        Dados principais
      </v-col>
      <v-col cols="6" sm="3" md="2" class="">
        <excel :data="[patientExcel]" :json_fields="json_fields_patient" button_name="Exportar" icon="mdi-download"
          :file_name="
            patient.name.replaceAll(' ', '_') + '_dados_gerais.xls'
          " />
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start">Nome completo</v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.mainData.fullName }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start">
                Data de nascimento
              </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.mainData.birthDate }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start"> Sexo </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.mainData.sex }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start">
                Nacionalidade
              </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.mainData.nationality }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start"> CPF </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.mainData.cpf }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start"> RG </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.mainData.rg }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start"> Nome da mãe </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.mainData.motherName }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="subtitle-1 font-weight-medium mb-5 mt-5">
        Dados de contato
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start">Nome do filho ou responsável</v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.contact.childOrGuardian }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start">
                Telefone do filho ou responsável
              </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.contact.childOrGuardianPhone }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start"> Endereço </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.address.address }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start"> Email </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.contact.email }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start"> Telefone </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.contact.phone }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="subtitle-1 font-weight-medium mb-5 mt-5">
        Informações do dispositivo
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start">Modelo</v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.device.model }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start"> Status </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.device.status }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start"> punho </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.device.arm }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start">
                Modo não pertube
              </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.device.modeNotDisturb }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="border-bottom">
            <v-row>
              <v-col cols="6" class="text-start"> Idioma </v-col>
              <v-col cols="6" class="text-end font-weight-light">
                {{ patient.device.language }}
              </v-col>
            </v-row>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="subtitle-1 font-weight-medium mb-5 mt-5">
        Comentários
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-textarea outlined disabled v-model="patient.comments.comments"></v-textarea>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</template>

<script>
export default {
  components:{
    Excel: () => import("../../../components/Excel/Excel.vue"),
  },
  props: {
    patient: {}
  },
  data(){
    return{
      json_fields_patient: {
        Id: "id",
        Nome: {
          field: "name",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        "Data de nascimento": "birthDate",
        Sexo: "sex",
        Nacionalidade: "nationality",
        Cpf: "cpf",
        "Nome da mãe": {
          field: "motherName",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        "Nome do filho ou responsável": {
          field: "childOrGuardian",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        "Telefone do filho ou responsável": {
          field: "childOrGuardianPhone",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        Endereço: {
          field: "address",
          callback: (e) => {
            const object = {
              state: e.state,
              city: e.city,
              uf: e.uf,
              cep: e.cep,
              district: e.distric,
              address: e.address,
              house_number: e.house_number,
            };

            return `Estado: ${object.state}\nCidade: ${object.city}\nUF: ${object.uf}\nCEP: ${object.cep}\nDistrito: ${object.district}\nNúmero: ${object.house_number}`;
          },
        },
        Email: {
          field: "email",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        Telefone: {
          field: "phone",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        Comentários: {
          field: "comments",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
      },
      patientExcel: {},
    }
  }
}
</script>