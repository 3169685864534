<template>
  <v-card color="#F5F5F5" class="rounded-0">
    <v-card-text>
      <v-row>
        <v-col cols="6" sm="9" md="10" class="subtitle-1 font-weight-medium mb-5">
          Atividade do SmartWatch
        </v-col>
        <v-col cols="6" sm="3" md="2">
          <excel :data="[
            {
              id: patient.id,
              patient: patient.name,
              status: patient.device.status,
              battery: patient.device.battery,
              lastUpdate: patient.device.lastUpdate,
            },
            ]" :json_fields="json_fields_activity_smartwatch" button_name="Exportar" icon="mdi-download" :file_name="
            patient.name.replaceAll(' ', '_') +
            '_atividade_do_smart_watch .xls'"
          />
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col class="white mb-2" cols="12">
              <v-row>
                <v-col cols="6" class="text-start">Status</v-col>
                <v-col cols="6" class="text-end font-weight-medium" :class="
                  patient.device.status == 'Ligado'
                    ? 'green--text'
                    : 'red--text'
                ">
                  {{ patient.device.status }}
                </v-col>
              </v-row>
            </v-col>
            <v-col class="white mb-2" cols="12">
              <v-row>
                <v-col cols="6" class="text-start">Nível de bateria</v-col>
                <v-col cols="6" class="text-end font-weight-light">
                  {{ patient.device.battery }}
                </v-col>
              </v-row>
            </v-col>
            <v-col class="white mb-2" cols="12">
              <v-row>
                <v-col cols="6" class="text-start">
                  Última atualização
                </v-col>
                <v-col cols="6" class="text-end font-weight-light">
                  {{ patient.device.lastUpdate }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-5">
          <v-row class="align-center">
            <v-col cols="6" sm="8" md="9" class="text-start subtitle-1 font-weight-medium">
              Sinais vitais
            </v-col>
            <v-col cols="6" sm="4" md="3" class="text-end font-weight-light">
              {{ lastUpdate }}
              <excel :data="patient.vitalSigns" :json_fields="json_fields_vital_signs" button_name="Exportar"
                icon="mdi-download" :file_name="
                  patient.name.replaceAll(' ', '_') +
                  '_sinais_vitais.xls'
                " :width="$vuetify.breakpoint.mobile ? '100%' : 150" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" xsm="6" md="4" lg="2" v-for="item in patient.vitalSigns" :key="item.id">
              <v-card height="100%" class="d-flex" color="#1C3B7B">
                <v-list-item three-line class="text-center white--text">
                  <v-list-item-content>
                    <div class="caption mb-3" style="width: 100%; height: 20%">
                      {{ item.title }}
                    </div>
                    <v-card-actions>
                      <v-btn width="100%" color="white" class="caption black--text">
                        {{ item.value }}
                      </v-btn>
                    </v-card-actions>
                    <v-list-item-subtitle class="white--text">
                      {{ item.footer }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mb-5 mt-5">
          <v-row class="">
            <v-col cols="12" sm="4 " md="8" class="text-start subtitle-1 font-weight-medium">
              Gráficos de acompanhamento
            </v-col>
            <v-col cols="12" sm="4" md="2" class="text-end font-weight-light">
              <v-select :items="filterItems" dense outlined v-model="filterItemSelected" width="100%"></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <excel :json_fields="json_fields_sensors" :data="dataLineExcel" button_name="Exportar" icon="mdi-download"
                :file_name="
                  patient.name.replaceAll(' ', '_') +
                  '_acompanhamento.xls'
                " :width="$vuetify.breakpoint.mobile ? '100%' : 'auto'" :height="40" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <LineChartPatient :data="filteredChartData" :key="Object.keys(dataLine).join()" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import LineChartPatient from "../../../components/Charts/LineChartPatient.vue";

export default {
  components: {
    LineChartPatient,
    Excel: () => import("../../../components/Excel/Excel.vue"),
  },
  props:{
    patient: {},
    dataLineExcel:[],
    showSensorCharts: Function,
    dataLine: {}
  },
  data(){
    return {
      filterItemSelected: "Todos",
      filterItems: [
        "Todos",
        "Temperatura",
        "Batimentos",
        "Sbp",
        "Dbp",
        "Saturação",
        "Sono total",
      ],
      lastUpdate: "Última atualização " + this.patient.device.lastUpdate,
      json_fields_activity_smartwatch: {
        id: "id",
        Paciente: {
          field: "patient",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        Status: "status",
        Bateria: "battery",
        "Última atualização": {
          field: "lastUpdate",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
      },
      json_fields_vital_signs: {
        Id: "id",
        Sensor: {
          field: "title",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        Valor: {
          field: "value",
          callback: (e) => {
            return e;
          },
        },
      },
      json_fields_sensors: {
        Dia: "day",
        Sensor: "sensor",
        valor: "value",
      },
    }
  },
  watch: {
    filterItemSelected: function (val) {
      this.showSensorCharts({
        user_id: this.$route.params.id,
        filter_name: val,
      });
    },
  },
  methods:{
  },
  computed:{
    filteredChartData() {
      return {
        tooltip: {
          trigger: "axis",
          // formatter: "{a} <br/>{b}: {c} (%)",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Dom", "Seg", "Ter", "Quarta", "Qui", "Sex", "Sáb"],
        },
        yAxis: {
          type: "value",
        },
        series: Object.entries(this.dataLine).map((el) => {
          return {
            name: el && el[0],
            type: "line",
            stack: "总量",
            emphasis: {
              focus: "series",
              label: {
                show: true,
                fontSize: "20",
                color: "gray",
              },
            },
            label: {
              show: false,
              position: "left",
            },
  
            data: Object.values(el[1]).map((e) => {
              if (e != null) return parseFloat(e).toFixed(1);
              return 0;
            }),
          };
        }),
      };
    },
  }
}
</script>