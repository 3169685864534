<template>
  <v-card color="#F5F5F5" class="rounded-0">
    <v-card-text>
      <v-row>
        <v-col cols="12" class="">
          <v-row>
            <v-col cols="6" sm="10" class="text-start subtitle-1 font-weight-medium pl-11">
              <span>Prontuários</span><br />
              <span class="caption font-weight-light">
                Registro de prontuários
              </span>
            </v-col>
            <v-col cols="12" class="pl-11 pr-11">
              <v-card elevation="0" color="transparent">
                <v-card-title>
                  <v-row class="align-center">
                    <v-col cols="12">
                      <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Buscar" single-line hide-details
                        dense />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card class="align-self-end" elevation="0" color="transparent">
                  <v-data-table :headers="attendanceHeaders" :items="attendancies" :search="search"
                    :custom-filter="filterOnlyNumber" item-key="serviceNumber" :loading="!attendancies">
                    <!-- Date -->
                    <template v-slot:[`item.date`]="{ item }">
                      <div class="d-flex justify-center" @click="
                        $router.push({
                          name: 'PatientShowAttendance',
                          params: {
                            id: $route.params.id,
                            attendance_id: item.id,
                            data: item,
                          },
                        })
                      ">
                        {{ item.date }}
                      </div>
                    </template>
                    <!-- Service Number -->
                    <template v-slot:[`item.serviceNumber`]="{ item }">
                      <div cols="12" class="subtitle-2 font-weight-medium" @click="
                        $router.push({
                          name: 'PatientShowAttendance',
                          params: {
                            id: $route.params.id,
                            attendance_id: item.id,
                            data: item,
                          },
                        })
                      ">
                        {{ item.serviceNumber }}
                      </div>
                    </template>
                    <!-- id -->
                    <template v-slot:[`item.id`]="{ item }">
                      <div class="d-flex justify-center" @click="
                        $router.push({
                          name: 'PatientShowAttendance',
                          params: {
                            id: $route.params.id,
                            attendance_id: item.id,
                            data: item,
                          },
                        })
                      ">
                        {{ item.id }}
                      </div>
                    </template>

                    <template v-slot:[`item.patient`]="{ item }">
                      <div @click="
                        $router.push({
                          name: 'PatientShowAttendance',
                          params: {
                            id: $route.params.id,
                            attendance_id: item.id,
                            data: item,
                          },
                        })
                      ">
                        {{ item.patient }}
                      </div>
                    </template>
                    <!-- Responsible -->
                    <template v-slot:[`item.responsible`]="{ item }">
                      <div class="subtitle-2 font-weight-medium" @click="
                        $router.push({
                          name: 'PatientShowAttendance',
                          params: {
                            id: $route.params.id,
                            attendance_id: item.id,
                            data: item,
                          },
                        })
                      ">
                        {{ item.responsible }}
                      </div>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <div class="font-weight-bold" :style="'color: ' + item.status.color" @click="
                        $router.push({
                          name: 'PatientShowAttendance',
                          params: {
                            id: item.patient_id,
                            attendance_id: item.id,
                            data: item,
                          },
                        })
                      ">
                        {{ item.status.title }}
                      </div>
                    </template>
                    <!-- Menu -->
                    <template v-slot:[`item.menu`]="{ item }">
                      <v-btn class="primary" @click="
                      $router.push({
                        name: 'PatientShowAttendance',
                        params: {
                          id: $route.params.id,
                          attendance_id: item.id,
                          data: item,
                        },
                      })">
                        Consultar prontuário
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

</template>

<script>
export default {
  components: {

  },
  props: {
    attendancies: [],
  },
  data() {
    return {
      search: "",
    }
  },
  methods: {
    filterOnlyNumber(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "number" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
  computed: {
    attendanceHeaders() {
      return [
        {
          text: "Data e Hora do atendimento",
          value: "date",
          width: "33%",
          align: "center",
        },
        {
          text: "N° do atendimento",
          value: "id",
          width: "33%",
          align: "center",
        },
        { text: "Ação", value: "menu", width: "33%", align: "center" },
      ];
    },
  }
}
</script>