var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-0",attrs:{"color":"#F5F5F5"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-start subtitle-1 font-weight-medium pl-11",attrs:{"cols":"6","sm":"10"}},[_c('span',[_vm._v("Prontuários")]),_c('br'),_c('span',{staticClass:"caption font-weight-light"},[_vm._v(" Registro de prontuários ")])]),_c('v-col',{staticClass:"pl-11 pr-11",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","color":"transparent"}},[_c('v-card-title',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card',{staticClass:"align-self-end",attrs:{"elevation":"0","color":"transparent"}},[_c('v-data-table',{attrs:{"headers":_vm.attendanceHeaders,"items":_vm.attendancies,"search":_vm.search,"custom-filter":_vm.filterOnlyNumber,"item-key":"serviceNumber","loading":!_vm.attendancies},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",on:{"click":function($event){return _vm.$router.push({
                        name: 'PatientShowAttendance',
                        params: {
                          id: _vm.$route.params.id,
                          attendance_id: item.id,
                          data: item,
                        },
                      })}}},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"item.serviceNumber",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"subtitle-2 font-weight-medium",attrs:{"cols":"12"},on:{"click":function($event){return _vm.$router.push({
                        name: 'PatientShowAttendance',
                        params: {
                          id: _vm.$route.params.id,
                          attendance_id: item.id,
                          data: item,
                        },
                      })}}},[_vm._v(" "+_vm._s(item.serviceNumber)+" ")])]}},{key:"item.id",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",on:{"click":function($event){return _vm.$router.push({
                        name: 'PatientShowAttendance',
                        params: {
                          id: _vm.$route.params.id,
                          attendance_id: item.id,
                          data: item,
                        },
                      })}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.patient",fn:function(ref){
                      var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.$router.push({
                        name: 'PatientShowAttendance',
                        params: {
                          id: _vm.$route.params.id,
                          attendance_id: item.id,
                          data: item,
                        },
                      })}}},[_vm._v(" "+_vm._s(item.patient)+" ")])]}},{key:"item.responsible",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"subtitle-2 font-weight-medium",on:{"click":function($event){return _vm.$router.push({
                        name: 'PatientShowAttendance',
                        params: {
                          id: _vm.$route.params.id,
                          attendance_id: item.id,
                          data: item,
                        },
                      })}}},[_vm._v(" "+_vm._s(item.responsible)+" ")])]}},{key:"item.status",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:('color: ' + item.status.color),on:{"click":function($event){return _vm.$router.push({
                        name: 'PatientShowAttendance',
                        params: {
                          id: item.patient_id,
                          attendance_id: item.id,
                          data: item,
                        },
                      })}}},[_vm._v(" "+_vm._s(item.status.title)+" ")])]}},{key:"item.menu",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.$router.push({
                      name: 'PatientShowAttendance',
                      params: {
                        id: _vm.$route.params.id,
                        attendance_id: item.id,
                        data: item,
                      },
                    })}}},[_vm._v(" Consultar prontuário ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }