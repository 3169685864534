<template>
  <v-row class="align-center">
    <v-col cols="12">
      <v-btn @click="$emit('update:step', false)" text class="pa-0 pr-2">
        <v-icon class="pr-1">mdi-chevron-left</v-icon> Voltar
      </v-btn>
    </v-col>
    <v-col cols="12" sm="4" md="7">
      <v-row>
        <v-col cols="12" class="pb-0 text-h6 black--text">{{
          anamnese.type
        }}</v-col>
        <v-col cols="12" class="pt-0 caption"
          >Realizada  dia {{ anamnese.date }}</v-col
        >
      </v-row>
    </v-col>
    <v-col cols="6" sm="4" md="2">
      <excel
        :data="dataAnamneseExcel"
        :json_fields="json_fields_anamnese_answers"
        button_name="Exportar"
        icon="mdi-download"
        :file_name="
          anamnese.type.replaceAll(' ', '_') + '_respostas_de_anamnese.xls'
        "
      />
    </v-col>
    <!-- <v-col cols="6" sm="4" md="3" class="">
      <v-btn
        @click="
          $router.push({
            name: 'UpdateAnamneseAnswer',
            params: { form_application_id: anamnese.id, data: [anamnese] },
          })
        "
        :width="$vuetify.breakpoint.mobile ? '100%' : 'auto'"
      >
        <v-icon class="pr-1">mdi-pencil</v-icon>
        Editar anamnese
      </v-btn>
    </v-col> -->
    <v-col
      class="mt-4"
      cols="12"
      v-for="(item, index) in anamnese.form_items"
      :key="index"
    >
      <v-row>
        <v-col cols="12" class="black--text">
          {{ item.name }}
        </v-col>
        <v-col
          class="pt-0 pb-0"
          cols="12"
          v-for="(answer, index) in item.form_item_answers"
          :key="index"
        >
          <v-subheader v-if="item.kind != 'multiselect' && item.kind != 'date'" class="pl-0 pb-0 pt-0">
            {{ answer.content ? answer.content.replace("true", "Sim").replace("false", "Não") : "Não informado" }}
          </v-subheader>
          <v-subheader v-if="item.kind == 'date'" class="pl-0 pb-0 pt-0">
            {{ answer.content ? `${answer.content.split('-')[2]}/${answer.content.split('-')[1]}/${answer.content.split('-')[0]}` : "Não informado" }}
          </v-subheader>
          <v-subheader
            v-if="item.kind === 'multiselect'"
            class="pl-0 pb-0 pt-0"
          >
            <v-select
              multiple
              dense
              outlined
              :items="answer.content_multiple || []"
              v-model="answer.content_multiple"
              chips
              disabled
            ></v-select>
          </v-subheader>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Excel from "../../../components/Excel/Excel.vue";
export default {
  components: { Excel },
  props: {
    anamnese: {
      required: true,
      type: Object,
    },
    patient: {
      required: true,
      type: Object,
    },
    step: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    dataAnamneseExcel: [],
    json_fields_anamnese_answers: {
      Id: "id",
      Paciente: {
        field: "patient",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Formulário: {
        field: "type",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Data: {
        field: "date",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Questão: {
        field: "question",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Resposta: {
        field: "answer",
        callback: (e) => {
          let textFormatted = "";

          if (typeof e == "string") {
            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }
          }

          if (typeof e == "object") {
            textFormatted = e.join("\n");
          }

          return textFormatted;
        },
      },
    },
  }),
  mounted() {
    this.formatAnamneseExcel();
  },
  methods: {
    formatAnamneseExcel() {
      const formItems = this.anamnese.form_items;

      formItems.map((el) => {
        if (el.form_item_answers[0].content == "true")
          el.form_item_answers[0].content = "Sim";
        if (el.form_item_answers[0].content == "false")
          el.form_item_answers[0].content = "Não";

        this.dataAnamneseExcel.push({
          id: el.id,
          patient: this.patient.name,
          type: this.anamnese.type,
          date: this.anamnese.date,
          question: el.name,
          answer: el.form_item_answers
            ? el.form_item_answers[0].content ||
              el.form_item_answers[0].content_multiple
            : "",
        });
      });
    },
  },
};
</script>

<style></style>
