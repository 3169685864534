<template>
  <v-row>
    <v-card color="#F5F5F5" class="rounded-0" height="55vh" width="100vw">
      <iframe src="https://eprevgestao.com.br/mapa/?cpf=05434961129" frameborder="0" width="100%"
        height="100%"></iframe>
    </v-card>
  </v-row>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {

    }
  }
}
</script>